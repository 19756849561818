import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexStroke,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexMarkers,
  ApexXAxis,
  ApexAnnotations,
  ApexLegend,
  ApexGrid,
  ApexYAxis,
} from "ng-apexcharts";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  markers: ApexMarkers;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  annotations: ApexAnnotations;
  legend: ApexLegend;
  grid: ApexGrid;
};
//https://apexcharts.com/docs/options/annotations/

@Component({
  selector: "app-goals-story",
  templateUrl: "./goals-story.component.html",
  styleUrls: ["./goals-story.component.scss"],
  providers: [DataService],
})
export class GoalsStoryComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  data: any = [];
  matchTime: number = 3600;

  loading: boolean = true;
  error: boolean = false;

  loaded: boolean = false;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {
    // let categories = [];
    // Array.from(Array(this.matchTime)).forEach((x, i) => {
    //   if (i === 0) {
    //     categories.push("");
    //   } else if (i === 2599) {
    //     categories.push("");
    //   } else if (i === 1) {
    //     categories.push("5:00");
    //   } else if (i === 2399) {
    //     categories.push("20:00");
    //   } else {
    //     categories.push("x" + i);
    //   }
    // });

    // console.log(categories);

    this.chartOptions = {
      series: [
        {
          name: "stepline-series",
          data: [],
        },
        {
          name: "stepline-series2",
          data: [],
        },
      ],
      xaxis: {
        type: "numeric",
        min: 0,
        max: 3600,
        tickAmount: 12,
        labels: {
          show: true,
          style: {
            fontWeight: 600,
          },
          formatter: (val: string) => this.formatSeconds(Number(val)),
        },
        axisBorder: {
          show: true,
          color: "#0a0a0a",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#0a0a0a",
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,

        labels: {
          style: {
            fontWeight: 600,
          },
          formatter: (val: number) => val.toFixed(2),
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        show: true,

        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      chart: {
        type: "line",
        height: 512,

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
      },
      stroke: {
        curve: "stepline",
        colors: ["#0a0a0a", "#c8293f"],
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      markers: {
        size: 6,
        colors: ["#0a0a0a", "#c8293f"],
        strokeColors: ["#0a0a0a", "#c8293f"],
      },
      legend: {
        show: false,
      },
      annotations: {
        xaxis: [
          {
            x: 1200,
            strokeDashArray: 0,
            borderColor: "#0a0a0a",
            label: {
              borderColor: "transparent",
              style: {
                color: "#0a0a0a",
                background: "transparent",
                fontSize: "12px",
                fontWeight: 600,
              },
              offsetY: -12,
              orientation: "horizontal",
              text: "1st period end",
            },
          },
          {
            x: 2400,
            strokeDashArray: 0,
            borderColor: "#0a0a0a",
            label: {
              borderColor: "transparent",
              style: {
                color: "#0a0a0a",
                background: "transparent",
                fontSize: "12px",
                fontWeight: 600,
              },
              offsetY: -12,
              orientation: "horizontal",
              text: "2nd period end",
            },
          },
        ],
        points: [],
      },
    };
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        let id = params["id"];

        this.dataService.getShots(id).subscribe(
          (loaded_data) => {
            this.data = loaded_data;

            this.updateGraph(loaded_data);
            this.loaded = true;
          },
          (err) => {
            this.loading = true;
            this.error = true;
            this.loaded = false;
          }
        );
      },
      (err) => {
        this.loading = true;
        this.error = true;
        this.loaded = false;
      }
    );
  }

  updateGraph(data: any) {
    let homeData = [];
    let awayData = [];

    let goals = [];
    let timeDataAll = [];

    data.match.xGStory.xGDataHome.forEach((item) => {
      homeData.push({ x: item.time, y: item.value });
      timeDataAll.push(item.time);
    });

    data.match.xGStory.xGDataVisitor.forEach((item) => {
      awayData.push({ x: item.time, y: item.value });
      timeDataAll.push(item.time);
    });

    data.match.xGStory.goalsHome.forEach((item) => {
      goals.push({
        x: item.time,
        y: item.xG,
        marker: {
          size: 0,
        },
        image: {
          path:
            "https://www.eliteleague.co.uk/photo/team/team_" +
            (data.match.home_id - 87) +
            ".png",
          width: 36,
          height: 36,
          offsetX: 0,
          offsetY: 18,
        },
      });
    });

    data.match.xGStory.goalsVisitor.forEach((item) => {
      goals.push({
        x: item.time,
        y: item.xG,
        marker: {
          size: 0,
        },
        image: {
          path:
            "https://www.eliteleague.co.uk/photo/team/team_" +
            (data.match.visitor_id - 87) +
            ".png",
          width: 36,
          height: 36,
          offsetX: 0,
          offsetY: 18,
        },
      });
    });

    this.chartOptions.series = [
      {
        name: "stepline-series",
        data: homeData,
      },
      {
        name: "stepline-series2",
        data: awayData,
      },
    ];

    this.ngZone.run(() => {
      // this.chartOptions.annotations = anota;

      this.chartOptions.yaxis.max = this.findMax();
      this.chart.updateOptions(this.chartOptions);

      let maxTime = Math.max.apply(null, timeDataAll);
      if (maxTime > 3600) {
        this.chartOptions.xaxis.max = 3600 + 5 * 60;
        this.chartOptions.xaxis.tickAmount = 13;

        this.chartOptions.annotations.xaxis.push({
          x: 3600,
          strokeDashArray: 0,
          borderColor: "#0a0a0a",
          label: {
            borderColor: "transparent",
            style: {
              color: "#0a0a0a",
              background: "transparent",
              fontSize: "12px",
              fontWeight: 600,
            },
            offsetY: -12,
            orientation: "horizontal",
            text: "3rd period end",
          },
        });
        this.chart.updateOptions(this.chartOptions);
      }
    });

    setTimeout(() => {
      let anota: any = [];
      goals.forEach((item) => {
        this.chart.addPointAnnotation(item);
        // anota.push(item);
      });
    }, 400);
  }

  formatSeconds = (s2: number) => {
    // return s2;
    let s = s2;
    if (s === 0) {
      return "00:00";
    } else {
      return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    }
  };

  stringy(data: any) {
    return JSON.stringify(data);
  }

  findMax() {
    let values: any[number] = [];
    this.data.match.xGStory.xGDataHome.forEach((item) => {
      values.push(item.value);
    });
    this.data.match.xGStory.xGDataVisitor.forEach((item) => {
      values.push(item.value);
    });
    return Math.ceil(Math.max.apply(null, values));
  }
}
