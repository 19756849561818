import { Component, OnInit } from '@angular/core';

import * as Konva from 'konva';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var require: any
var classifyPoint = require("robust-point-in-polygon");


@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss'],
  providers: [DataService]

})
export class ZonesComponent implements OnInit {

  shots_data: any = [];

  loading: boolean = true;
  error: boolean = false;


  all_shots: any = [];

  home_top_polygon: any = [[-85, -100], [-50, -100], [-50, -45], [-65, - 45], [-85, -25]];
  home_center_polygon: any = [[-85, -25], [-65, -45], [-50, -45], [-50, 45], [-65, 45], [-85, 25]];
  home_bottom_polygon: any = [[-85, 25], [-65, 45], [-50, 45], [-50, 100], [-85, 100]];
  home_defense_polygon: any = [[-50, -100], [-30, -100], [-30, 100], [-50, 100]];

  away_top_polygon: any = [[50, -100], [85, -100], [85, -25], [65, -45], [50, -45]];
  away_center_polygon: any = [[50, -45], [65, -45], [85, -25], [85, 25], [65, 45], [50, 45]];
  away_bottom_polygon: any = [[50, 45], [65, 45], [85, 25], [85, 100], [50, 100]];
  away_defense_polygon: any = [[30, -100], [50, -100], [50, 100], [30, 100]];



  home_top: number = 0;
  home_center: number = 0;
  home_bottom: number = 0;
  home_defense: number = 0;
  away_top: number = 0;
  away_center: number = 0;
  away_bottom: number = 0;
  away_defense: number = 0;


  home_all_shots: number = 0;
  away_all_shots: number = 0;

  team_id: number;
  opponent_id: number;

  constructor(private dataService: DataService, private activatedRoute: ActivatedRoute) {
    this.loadData();


    //alert("je tam?" + ));

  }

  /*
    -1 if point is contained inside loop
    0 if point is on the boundary of loop
    1 if point is outside loop
  */

  ngOnInit() {

  }

  loadData() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let id = params['id'];

      this.dataService.getShotflow(id).subscribe(loaded_data => {


        this.team_id = loaded_data['teamInfo']['team'][0]['id'];
        this.opponent_id = loaded_data['teamInfo']['opponent'][0]['id'];






        this.dataService.getFaceoff(id).subscribe(loaded_data => {

          this.shots_data = loaded_data;

          //console.log(JSON.stringify(this.shots_data));

          this.shots_data['match']['shots'].forEach((item) => {


            if (item["team_id"] == this.opponent_id) {

              this.home_all_shots = this.home_all_shots + 1;

            } else if (item["team_id"] == this.team_id) {

              this.away_all_shots = this.away_all_shots + 1;

            }




            //je v home_top? 
            if (classifyPoint(this.home_top_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v home_top");
              this.home_top = this.home_top + 1;
            }
            //je v home_center? 
            else if (classifyPoint(this.home_center_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v home_center");
              this.home_center = this.home_center + 1;
            }
            //je v home_bottom? 
            else if (classifyPoint(this.home_bottom_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v home_bottom");
              this.home_bottom = this.home_bottom + 1;
            }
            //je v home_defense? 
            else if (classifyPoint(this.home_defense_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v home_defense");
              this.home_defense = this.home_defense + 1;
            } else {
              //console.log("Střela v prdeli.")
            }



            //je v away_top? 
            if (classifyPoint(this.away_top_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v away_top");
              this.away_top = this.away_top + 1;
            }
            //je v away_center? 
            else if (classifyPoint(this.away_center_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v away_center");
              this.away_center = this.away_center + 1;
            }
            //je v away_bottom? 
            else if (classifyPoint(this.away_bottom_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v away_bottom");
              this.away_bottom = this.away_bottom + 1;
            }
            //je v away_defense? 
            else if (classifyPoint(this.away_defense_polygon, [item['coordinate_x'], item['coordinate_y']]) == -1) {
              //console.log("bod je v away_defense");
              this.away_defense = this.away_defense + 1;
            } else {
              //console.log("Střela v prdeli.")
            }

          });

          /*
          console.log("domaci celkem: " + this.home_all_shots);
          console.log("domaci vpravo: " + this.home_top);
          console.log("domaci stred: " + this.home_center);
          console.log("domaci vlevo: " + this.home_bottom);
          console.log("domaci nahore: " + this.home_defense);
          console.log("-------");
          console.log("hoste celkem: " + this.away_all_shots);
          console.log("hoste vpravo: " + this.away_top);
          console.log("hoste stred: " + this.away_center);
          console.log("hoste vlevo: " + this.away_bottom);
          console.log("hoste nahore: " + this.away_defense);
          */


          this.loading = false;

        }, err => {
          this.loading = true;
          this.error = true;
        }
        );






      }, err => {
        this.loading = true;
        this.error = true;
      }
      );




    }, err => {
      //alert("Chyba.");
      this.loading = true;
      this.error = true;
    }
    );





  }



  mapClick(event) {
    //alert();
    let x = event.offsetX - 686 / 2;
    let y = event.offsetY - 344 / 2;

    x = Math.round(this.Remap(x, 0, 686, 0, 200));
    y = Math.round(this.Remap(y, 0, 344, 0, 200));

    // alert("x: " + x + " y: " + y);

    //console.log(this.home_all_shots);
    //console.log(this.away_all_shots);
  }


  Remap(x, in_min, in_max, out_min, out_max) {
    return ((x - in_min) / (in_max - in_min)) * (out_max - out_min) + out_min;
  }


  zaokrouhleni(value: number) {
    if (isNaN(value)) {
      return "0";
    } else {
      return Math.round(value);
    }
  }

}
