import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { DataService } from "../services/data.service";
import { ActivatedRoute, Params } from "@angular/router";
declare const simpleheat: any;

// import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: "app-heatmap-full",
  templateUrl: "./heatmap_full.component.html",
  styleUrls: ["./heatmap_full.component.scss"],
  providers: [DataService],
})
export class HeatmapFullComponent implements OnInit {
  shots: any = [];

  loading: boolean = true;
  error: boolean = false;

  heat_home: any;
  heat_away: any;

  home_name: string = "";
  away_name: string = "";

  @ViewChild("heatmap_home") heatmap_home: ElementRef;
  context: CanvasRenderingContext2D;

  show_teams: string = "";
  isMobile: boolean = false;

  constructor(
    // private deviceService: DeviceDetectorService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {
    // this.isMobile = this.deviceService.isMobile();
    this.loadData();

    setInterval(() => {
      this.loadData();
    }, 60000);
  }

  ngOnInit() {}

  loadData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        let id = params["id"];
        let type = params["type"];
        this.show_teams = params["show_teams"];

        this.dataService.getShots(id).subscribe(
          (loaded_data) => {
            this.shots = loaded_data;

            this.home_name = loaded_data["match"]["home_name"];
            this.away_name = loaded_data["match"]["visitor_name"];

            this.heat_home = simpleheat("heatmap_home");
            this.heat_home.radius(25, 40);

            if (type == "customx") {
              this.heat_home.gradient({
                0.5: "#a5a5a5",
                0.8: "#6c6c71",
                0.9: "#0a0a0a",
              });
            }

            this.heat_away = simpleheat("heatmap_away");
            this.heat_away.radius(25, 40);
            if (type == "customx") {
              this.heat_away.gradient({
                0.5: "#eab4b9",
                0.8: "#f3929c",
                0.9: "#c8293f",
              });
            }

            let shots_home = [];
            this.shots["match"]["shots"].forEach((item) => {
              if (item["team_id"] == loaded_data["match"]["home_id"]) {
                let top = this.calculateVerticalPositionToPercent(
                  item["coordinate_x"]
                );
                let left = this.calculateHorizontalPositionToPercent(
                  item["coordinate_x"],
                  item["coordinate_y"]
                );
                let pos_x = Math.ceil((640 * left) / 100);
                let pos_y = Math.ceil((640 * top) / 100);
                shots_home.push([pos_x, pos_y, 1]);
              }
            });
            this.heat_home.data(shots_home);
            this.heat_home.draw();

            let shots_away = [];
            this.shots["match"]["shots"].forEach((item) => {
              if (item["team_id"] == loaded_data["match"]["visitor_id"]) {
                let top = this.calculateVerticalPositionToPercent(
                  item["coordinate_x"]
                );
                let left = this.calculateHorizontalPositionToPercent(
                  item["coordinate_x"],
                  item["coordinate_y"]
                );
                let pos_x = Math.ceil((640 * left) / 100);
                let pos_y = Math.ceil((640 * top) / 100);
                shots_away.push([pos_x, pos_y, 1]);
              }
            });
            this.heat_away.data(shots_away);
            this.heat_away.draw();

            this.loading = false;
          },
          (err) => {
            //alert("Chyba.");
            this.loading = true;
            this.error = true;
          }
        );
      },
      (err) => {
        //alert("Chyba.");
        this.loading = true;
        this.error = true;
      }
    );
  }

  calculateHorizontalPositionToPercent(x, y) {
    var position = Math.ceil((y + 100) / 2);
    return x <= 0 ? position : 100 - position;
  }

  // zrcadlové otočení pro hosty (X hodnoty v kladných číslech)
  calculateVerticalPositionToPercent(x) {
    return x <= 0 ? Math.ceil(x + 100) : Math.ceil(100 - x);
  }
}
