import { Component, OnInit } from "@angular/core";

import * as Konva from "konva";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-deserve2win",
  templateUrl: "./deserve2win.component.html",
  styleUrls: ["./deserve2win.component.scss"],
  providers: [DataService],
})
export class Deserve2WinComponent implements OnInit {
  loading: boolean = true;
  error: boolean = false;

  data: any = [];

  constructor(
    // private deviceService: DeviceDetectorService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {
    this.loadData();
  }

  ngOnInit() {}

  loadData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        let id = params["id"];

        this.dataService.getShots(id).subscribe(
          (loaded_data) => {
            this.data = loaded_data;
            this.loading = false;
          },
          (err) => {
            this.loading = true;
            this.error = true;
          }
        );
      },
      (err) => {
        this.loading = true;
        this.error = true;
      }
    );
  }

  countHomeShots() {
    let count = 0;
    this.data.match.shots.forEach((item) => {
      if (item.team_id === this.data.match.home_id) count = count + 1;
    });
    return count;
  }

  countAwayShots() {
    let count = 0;
    this.data.match.shots.forEach((item) => {
      if (item.team_id === this.data.match.visitor_id) count = count + 1;
    });
    return count;
  }

  getHomeXdMax() {
    let values = [];
    this.data.match.deserve2win.homeStats.forEach((item) => {
      values.push(item.value.toFixed(2));
    });

    //@ts-ignore
    return Math.max(...values);
  }

  getAwayXdMax() {
    let values = [];
    this.data.match.deserve2win.visitorStats.forEach((item) => {
      values.push(item.value.toFixed(2));
    });

    return Math.max(...values);
  }

  getShots(type: string, team_id: number) {
    let count = 0;

    this.data.match.shots.forEach((item) => {
      if (item.xG_type === type && item.team_id === team_id) {
        count = count + 1;
      }
    });

    return count;
  }

  getXg(type: string, team_id: number) {
    let count = 0;

    this.data.match.shots.forEach((item) => {
      if (item.xG_type === type && item.team_id === team_id) {
        count = count + item.xG;
      }
    });

    return count.toFixed(2);
  }

  getGoals(type: string, team_id: number) {
    let count = 0;

    this.data.match.shots.forEach((item) => {
      if (
        item.xG_type === type &&
        item.team_id === team_id &&
        item.match_shot_resutl_id === 4
      ) {
        count = count + 1;
      }
    });

    return count;
  }

  stringy(data: any) {
    return JSON.stringify(data);
  }

  getHomePercent(home: number, visitor: number) {
    return ((100 / (home + visitor)) * home).toFixed(0);
  }

  getVisitorPercent(home: number, visitor: number) {
    return ((100 / (home + visitor)) * visitor).toFixed(0);
  }
}
