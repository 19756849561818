import { Injectable } from "@angular/core";
import { Http } from "@angular/http";

import { map, filter, switchMap } from "rxjs/operators";

@Injectable()
export class DataService {
  constructor(private http: Http) {}

  getShotflow(id: number) {
    return this.http
      .get(
        "https://s3.eu-west-1.amazonaws.com/eihl.hokejovyzapis.cz/visualization/game-flow/" +
          id +
          ".json?cache_id=" +
          Math.floor(Date.now() / 1000)
      )
      .pipe(map((response: any) => response.json()));
  }

  getGameImpact(id: number, team_id: number) {
    return this.http
      .get(
        "https://s3.eu-west-1.amazonaws.com/eihl.hokejovyzapis.cz/match-team-stats-corsi/" +
          id +
          "/" +
          team_id +
          ".json?cache_id=" +
          Math.floor(Date.now() / 1000)
      )
      .pipe(map((response: any) => response.json()));
  }

  getFaceoff(id: number) {
    return this.http
      .get(
        "https://s3.eu-west-1.amazonaws.com/eihl.hokejovyzapis.cz/visualization/shots/" +
          id +
          ".json?cache_id=" +
          Math.floor(Date.now() / 1000)
      )
      .pipe(map((response: any) => response.json()));
  }

  getShots(id: number) {
    return this.http
      .get(
        "https://s3.eu-west-1.amazonaws.com/eihl.hokejovyzapis.cz/visualization/shots/" +
          id +
          ".json?cache_id=" +
          Math.floor(Date.now() / 1000)
      )
      .pipe(map((response: any) => response.json()));
  }
}
