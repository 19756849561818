import { Component, OnInit } from "@angular/core";

import * as Konva from "konva";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
// import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: "app-faceoff",
  templateUrl: "./faceoff.component.html",
  styleUrls: ["./faceoff.component.scss"],
  providers: [DataService],
})
export class FaceoffComponent implements OnInit {
  faceoff_data: any = [];

  loading: boolean = true;
  error: boolean = false;

  team_home: string = "";
  team_away: string = "";

  isMobile: boolean = false;

  constructor(
    // private deviceService: DeviceDetectorService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {
    // this.isMobile = deviceService.isMobile();

    this.loadData();
  }

  ngOnInit() {}

  loadData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        let id = params["id"];

        this.dataService.getFaceoff(id).subscribe(
          (loaded_data) => {
            this.faceoff_data = loaded_data;
            //alert(JSON.stringify(loaded_data));

            this.team_home = loaded_data["match"]["home_name"];
            this.team_away = loaded_data["match"]["visitor_name"];

            this.loading = false;
          },
          (err) => {
            //alert("Chyba.");
            this.loading = true;
            this.error = true;
          }
        );
      },
      (err) => {
        //alert("Chyba.");
        this.loading = true;
        this.error = true;
      }
    );
  }

  loadHome(type: string) {
    //fix kvuli grafu, kdyz je totiz 100, tak graf ukazuje nejakych 99.. cca
    let value = "";
    if (this.loading == false) {
      if (
        this.faceoff_data["match"]["faceoffs_percent"][type]["visitor"] != 100
      ) {
        value =
          Math.round(
            this.faceoff_data["match"]["faceoffs_percent"][type]["visitor"]
          ) + " 100";
      } else {
        value = "101 100";
      }

      if (
        this.faceoff_data["match"]["faceoffs_percent"][type]["visitor"] ==
        this.faceoff_data["match"]["faceoffs_percent"][type]["home"]
      ) {
        value = "50 100";
      }

      return value;
    }
  }

  loadHomePercent(type: string) {
    if (this.loading == false) {
      return Math.round(
        this.faceoff_data["match"]["faceoffs_percent"][type]["home"]
      );
    }
  }

  loadAwayPercent(type: string) {
    if (this.loading == false) {
      return Math.round(
        this.faceoff_data["match"]["faceoffs_percent"][type]["visitor"]
      );
    }
  }
}
