import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { DataService } from "../services/data.service";
import { ActivatedRoute, Params } from "@angular/router";
declare const simpleheat: any;

@Component({
  selector: "app-heatmap",
  templateUrl: "./heatmap.component.html",
  styleUrls: ["./heatmap.component.scss"],
  providers: [DataService],
})
export class HeatmapComponent implements OnInit {
  shots: any = [];

  loading: boolean = true;
  error: boolean = false;

  team_id: number;

  heat: any;
  @ViewChild("heatmap") heatmap: ElementRef;
  context: CanvasRenderingContext2D;

  home_name: string = "";
  away_name: string = "";

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {
    this.loadData();
    setInterval(() => {
      this.loadData();
    }, 60000);
  }

  ngOnInit() {
    this.heat = simpleheat("heatmap");
    this.heat.radius(20, 25);
  }

  loadData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        let id = params["id"];
        let where = params["where"];
        let type = params["type"];

        this.dataService.getShots(id).subscribe(
          (loaded_data) => {
            this.shots = loaded_data;

            if (where == "home") {
              this.team_id = loaded_data["match"]["home_id"];

              if (type == "custom") {
                this.heat.gradient({
                  0.5: "#eab4b9",
                  0.8: "#f3929c",
                  0.9: "#c8293f",
                });
              }
            } else if (where == "away") {
              this.team_id = loaded_data["match"]["visitor_id"];

              if (type == "custom") {
                this.heat.gradient({
                  0.5: "#a5a5a5",
                  0.8: "#6c6c71",
                  0.9: "#0a0a0a",
                });
              }
            } else {
              alert("Error.");
            }

            let shots = [];
            this.shots["match"]["shots"].forEach((item) => {
              if (item["team_id"] == this.team_id) {
                let top = this.calculateVerticalPositionToPercent(
                  item["coordinate_x"]
                );
                let left = this.calculateHorizontalPositionToPercent(
                  item["coordinate_x"],
                  item["coordinate_y"]
                );
                let pos_x = Math.ceil((600 * left) / 100);
                let pos_y = Math.ceil((600 * top) / 100);

                shots.push([pos_x, pos_y, 1]);
              }
            });
            this.heat.data(shots);
            this.heat.draw();

            this.loading = false;
          },
          (err) => {
            //alert("Chyba.");
            this.loading = true;
            this.error = true;
          }
        );
      },
      (err) => {
        //alert("Chyba.");
        this.loading = true;
        this.error = true;
      }
    );
  }

  calculateHorizontalPositionToPercent(x, y) {
    var position = Math.ceil((y + 100) / 2);
    return x <= 0 ? position : 100 - position;
  }

  // zrcadlové otočení pro hosty (X hodnoty v kladných číslech)
  calculateVerticalPositionToPercent(x) {
    return x <= 0 ? Math.ceil(x + 100) : Math.ceil(100 - x);
  }
}
