import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { ShotflowComponent } from "./shotflow/shotflow.component";
import { GameimpactComponent } from "./gameimpact/gameimpact.component";
import { FaceoffComponent } from "./faceoff/faceoff.component";
import { HomeComponent } from "./home/home.component";
import { ZonesComponent } from "./zones/zones.component";
import { HeatmapComponent } from "./heatmap/heatmap.component";
import { HeatmapFullComponent } from "./heatmap_full/heatmap_full.component";
import { ShotmapComponent } from "./shotmap/shotmap.component";
import { Deserve2WinComponent } from "./deserve2win/deserve2win.component";

import { RouterModule, Routes } from "@angular/router";

import { ChartsModule } from "ng2-charts";
import { HttpModule } from "@angular/http";

import { NgxEasypiechartModule } from "ngx-easypiechart";
import {} from "ngx-easypiechart";

import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { GoalsStoryComponent } from "./goals-story/goals-story.component";

import { NgApexchartsModule } from "ng-apexcharts";
// import { DeviceDetectorModule } from 'ngx-device-detector';

export const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "gameflow/:id", component: ShotflowComponent },
  { path: "gameimpact/:id/:stadium", component: GameimpactComponent },
  { path: "faceoff/:id", component: FaceoffComponent },
  { path: "zones/:id", component: ZonesComponent },
  { path: "heatmap/:id/:where/:type", component: HeatmapComponent },
  { path: "heatmap-full/:id/:type", component: HeatmapFullComponent },
  {
    path: "heatmap-full/:id/:type/:show_teams",
    component: HeatmapFullComponent,
  },
  { path: "shotmap/:id/:where", component: ShotmapComponent },
  { path: "deserve2win/:id", component: Deserve2WinComponent },
  { path: "goals-story/:id", component: GoalsStoryComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    ShotflowComponent,
    GameimpactComponent,
    FaceoffComponent,
    HomeComponent,
    ZonesComponent,
    HeatmapComponent,
    HeatmapFullComponent,
    ShotmapComponent,
    Deserve2WinComponent,
    GoalsStoryComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { enableTracing: true }),
    ChartsModule,
    HttpModule,
    NgxEasypiechartModule,
    NgSelectModule,
    FormsModule,
    NgApexchartsModule,
    // DeviceDetectorModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
