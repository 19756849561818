import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import * as Konva from "konva";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
declare const simpleheat: any;

// import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: "app-shotmap",
  templateUrl: "./shotmap.component.html",
  styleUrls: ["./shotmap.component.scss"],
  providers: [DataService],
})
export class ShotmapComponent implements OnInit {
  shots: any = [];

  loading: boolean = true;
  error: boolean = false;

  team_id: number;

  player: number = null;
  players_list: any[] = [];

  shot_type: string = "all";
  slot_show: boolean = true;

  team_name: string = "";

  shots_list: any = [];
  data: any = [];

  isMobile: boolean = false;

  activeXG: string = "";

  constructor(
    // private deviceService: DeviceDetectorService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {
    // this.isMobile = this.deviceService.isMobile();

    this.loadData();
    setInterval(() => {
      this.loadData();
    }, 60000);
  }

  ngOnInit() {}

  loadData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        let id = params["id"];
        let where = params["where"];

        this.dataService.getShots(id).subscribe(
          (loaded_data) => {
            this.shots = loaded_data;
            this.data = loaded_data;

            if (where == "home") {
              this.team_id = loaded_data["match"]["home_id"];
              this.team_name = loaded_data["match"]["home_name"];
            } else if (where == "away") {
              this.team_id = loaded_data["match"]["visitor_id"];
              this.team_name = loaded_data["match"]["visitor_name"];
            } else {
              console.log("Error.");
            }

            this.loadPlayers(loaded_data);
            this.reloadShots();
          },
          (err) => {
            //alert("Chyba.");
            this.loading = true;
            this.error = true;
          }
        );
      },
      (err) => {
        //alert("Chyba.");
        this.loading = true;
        this.error = true;
      }
    );
  }

  loadPlayers(data: any[]) {
    data["match"]["shots"].forEach((item) => {
      if (item["team_id"] == this.team_id)
        this.players_list.push({
          id: item["player_id"],
          jersey: item["jersey"],
          name:
            "#" +
            item["jersey"] +
            " " +
            item["first_name"] +
            " " +
            item["last_name"],
        });
    });

    var uniq = {};
    this.players_list = [
      //@ts-ignore
      ...this.players_list.filter(
        (obj) => !uniq[obj.id] && (uniq[obj.id] = true)
      ),
    ];

    this.players_list.sort(function (a, b) {
      return a.jersey < b.jersey ? -1 : a.jersey > b.jersey ? 1 : 0;
    });
  }

  reloadShots() {
    this.loading = true;
    this.shots_list = [];
    let list = [];
    this.shots["match"]["shots"].forEach((item) => {
      if (item["team_id"] == this.team_id) {
        let top = this.calculateVerticalPositionToPercent(item["coordinate_x"]);
        let left = this.calculateHorizontalPositionToPercent(
          item["coordinate_x"],
          item["coordinate_y"]
        );
        let pos_x = Math.ceil((640 * left) / 100);
        let pos_y = Math.ceil((640 * top) / 100);

        if (this.player == null) {
          if (this.shot_type == "all") {
            list.push({
              x: pos_x,
              y: pos_y,
              type: item["match_shot_resutl_id"],
              name:
                "#" +
                item["jersey"] +
                " " +
                item["first_name"] +
                " " +
                item["last_name"],
              xG: item["xG"],
              xG_type: item["xG_type"],
            });
          } else if (this.shot_type == "goal") {
            if (item["match_shot_resutl_id"] == 4) {
              list.push({
                x: pos_x,
                y: pos_y,
                type: item["match_shot_resutl_id"],
                name:
                  "#" +
                  item["jersey"] +
                  " " +
                  item["first_name"] +
                  " " +
                  item["last_name"],
                xG: item["xG"],
                xG_type: item["xG_type"],
              });
            }
          } else if (this.shot_type == "saved") {
            if (item["match_shot_resutl_id"] == 1) {
              list.push({
                x: pos_x,
                y: pos_y,
                type: item["match_shot_resutl_id"],
                name:
                  "#" +
                  item["jersey"] +
                  " " +
                  item["first_name"] +
                  " " +
                  item["last_name"],
                xG: item["xG"],
                xG_type: item["xG_type"],
              });
            }
          } else if (this.shot_type == "blocked") {
            if (item["match_shot_resutl_id"] == 3) {
              list.push({
                x: pos_x,
                y: pos_y,
                type: item["match_shot_resutl_id"],
                name:
                  "#" +
                  item["jersey"] +
                  " " +
                  item["first_name"] +
                  " " +
                  item["last_name"],
                xG: item["xG"],
                xG_type: item["xG_type"],
              });
            }
          } else if (this.shot_type == "wide") {
            if (item["match_shot_resutl_id"] == 2) {
              list.push({
                x: pos_x,
                y: pos_y,
                type: item["match_shot_resutl_id"],
                name:
                  "#" +
                  item["jersey"] +
                  " " +
                  item["first_name"] +
                  " " +
                  item["last_name"],
                xG: item["xG"],
                xG_type: item["xG_type"],
              });
            }
          }
        } else {
          if (item["player_id"] == this.player) {
            if (this.shot_type == "all") {
              list.push({
                x: pos_x,
                y: pos_y,
                type: item["match_shot_resutl_id"],
                name:
                  "#" +
                  item["jersey"] +
                  " " +
                  item["first_name"] +
                  " " +
                  item["last_name"],
                xG: item["xG"],
                xG_type: item["xG_type"],
              });
            } else if (this.shot_type == "goal") {
              if (item["match_shot_resutl_id"] == 4) {
                list.push({
                  x: pos_x,
                  y: pos_y,
                  type: item["match_shot_resutl_id"],
                  name:
                    "#" +
                    item["jersey"] +
                    " " +
                    item["first_name"] +
                    " " +
                    item["last_name"],
                  xG: item["xG"],
                  xG_type: item["xG_type"],
                });
              }
            } else if (this.shot_type == "saved") {
              if (item["match_shot_resutl_id"] == 1) {
                list.push({
                  x: pos_x,
                  y: pos_y,
                  type: item["match_shot_resutl_id"],
                  name:
                    "#" +
                    item["jersey"] +
                    " " +
                    item["first_name"] +
                    " " +
                    item["last_name"],
                  xG: item["xG"],
                  xG_type: item["xG_type"],
                });
              }
            } else if (this.shot_type == "blocked") {
              if (item["match_shot_resutl_id"] == 3) {
                list.push({
                  x: pos_x,
                  y: pos_y,
                  type: item["match_shot_resutl_id"],
                  name:
                    "#" +
                    item["jersey"] +
                    " " +
                    item["first_name"] +
                    " " +
                    item["last_name"],
                  xG: item["xG"],
                  xG_type: item["xG_type"],
                });
              }
            } else if (this.shot_type == "wide") {
              if (item["match_shot_resutl_id"] == 2) {
                list.push({
                  x: pos_x,
                  y: pos_y,
                  type: item["match_shot_resutl_id"],
                  name:
                    "#" +
                    item["jersey"] +
                    " " +
                    item["first_name"] +
                    " " +
                    item["last_name"],
                  xG: item["xG"],
                  xG_type: item["xG_type"],
                });
              }
            }
          }
        }
      }
    });

    if (this.activeXG !== "") {
      let filter = list.filter((obj) => obj.xG_type === this.activeXG);
      console.log(list);
      this.shots_list = filter;
    } else {
      this.shots_list = list;
    }

    this.loading = false;
  }

  setShotType(type: string) {
    if (type === "all") {
      this.activeXG = "";
    }
    this.shot_type = type;
    this.reloadShots();
  }

  setSlot(slot: boolean) {
    this.slot_show = slot;
  }

  calculateHorizontalPositionToPercent(x, y) {
    var position = Math.ceil((y + 100) / 2);
    return x <= 0 ? position : 100 - position;
  }

  toggleXg(type: string) {
    if (this.activeXG === type) {
      this.activeXG = "";
    } else {
      this.activeXG = type;
    }
    this.reloadShots();
  }

  // zrcadlové otočení pro hosty (X hodnoty v kladných číslech)
  calculateVerticalPositionToPercent(x) {
    return x <= 0 ? Math.ceil(x + 100) : Math.ceil(100 - x);
  }

  getShots(type: string, team_id: number) {
    let count = 0;

    this.data.match.shots.forEach((item) => {
      if (item.xG_type === type && item.team_id === team_id) {
        count = count + 1;
      }
    });

    return count;
  }

  getXg(type: string, team_id: number) {
    let count = 0;

    this.data.match.shots.forEach((item) => {
      if (item.xG_type === type && item.team_id === team_id) {
        count = count + item.xG;
      }
    });

    if (count !== null) {
      return count.toFixed(2);
    } else {
      return 0;
    }
  }

  getGoals(type: string, team_id: number) {
    let count = 0;

    this.data.match.shots.forEach((item) => {
      if (
        item.xG_type === type &&
        item.team_id === team_id &&
        item.match_shot_resutl_id === 4
      ) {
        count = count + 1;
      }
    });

    return count;
  }

  formatShotXg(xg: number) {
    if (xg === null) {
      return "";
    } else {
      return xg.toFixed(2);
    }
  }

  stringy(data: any) {
    return JSON.stringify(data);
  }
}
