import { Component, OnInit } from '@angular/core';

import * as Konva from 'konva';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

//gameipact se musi udelat ruznou barvou cervene a cerne

@Component({
  selector: 'app-gameimpact',
  templateUrl: './gameimpact.component.html',
  styleUrls: ['./gameimpact.component.scss'],
  providers: [DataService]

})
export class GameimpactComponent implements OnInit {

  sdp_data: any = [];

  logo_size: number;
  logo_size_half: number;

  graph_width: number;
  graph_height: number;
  screen_width: number;
  screen_height: number;

  loading: boolean = true;
  error: boolean = false;
  max_icetime: number = 0;
  min_icetime: number = 0;

  stadium: string = "";

  constructor(private dataService: DataService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {




    this.activatedRoute.params.subscribe((params: Params) => {
      let id = params['id'];

      let stadium = params['stadium'];
      this.stadium = stadium;

      this.dataService.getShotflow(id).subscribe(loaded_data => {


        let team_id = 0;


        if (stadium == "home") {
          team_id = loaded_data['teamInfo']['team'][0]['id']
        } else if (stadium == "away") {
          team_id = loaded_data['teamInfo']['opponent'][0]['id']
        }


        this.dataService.getGameImpact(id, team_id).subscribe(loaded_data => {

          this.sdp_data = loaded_data;



          this.logo_size = 40;
          this.logo_size_half = this.logo_size / 2;


          this.graph_width = 500;
          this.graph_height = 500;

          document.getElementById("graph-container").style.width = this.graph_width + 70 + "px";
          document.getElementById("graph-container").style.height = this.graph_height + 70 + "px";


          const stage = new Konva.Stage({
            container: 'container',
            width: this.graph_width,
            height: this.graph_height
          });




          /* LOAD DATA */

          // Najdi meze grafu (bounding box)
          //var maxX = Math.max.apply(Math, this.sdp_data.map(function (o) { return o['cf60']; }))
          //var minX = Math.min.apply(Math, this.sdp_data.map(function (o) { return o['cf60']; }))
          //var maxY = Math.max.apply(Math, this.sdp_data.map(function (o) { return o['ca60']; }))
          //var minY = Math.min.apply(Math, this.sdp_data.map(function (o) { return o['ca60']; }))

          var maxX = 90;
          var minX = 10;
          var minY = 10;
          var maxY = 90;

          //console.log("BB: <" + this.screen_width + ", " + this.screen_height + ">")
          //console.log("X: <" + minX + ", " + maxX + ">")
          //console.log("Y: <" + minY + ", " + maxY + ">")

          // Přidej/uber 0.5% na všechny strany ať to není namačkané v grafu
          minX -= 0
          maxX += 0
          minY -= 0
          maxY += 0

          // TODO Vykresli osy

          //console.log("X: <" + minX + ", " + maxX + ">")
          //console.log("Y: <" + minY + ", " + maxY + ">")



          //najdi nejvetsi icetime
          let ice_times = [];
          this.sdp_data.forEach(element => {
            ice_times.push(element["statistics"]["timeonice"]);
          });
          this.max_icetime = Math.max.apply(Math, ice_times.map(function (o) { return o; }))
          this.min_icetime = Math.min.apply(Math, ice_times.map(function (o) { return o; }))


          var canvas_inner_for_data = document.getElementById("container");

          this.sdp_data.forEach(element => {



            //vypocet velikosti kruhu
            var circle_size = this.Remap(element["statistics"]['timeonice'], this.min_icetime, this.max_icetime, 10, 30);

            var player_circle = document.createElement("div");
            player_circle.setAttribute("id", "player");

            if (this.stadium == "home") {
              player_circle.className += " player-circle";
            } else {
              player_circle.className += " player-circle";

              player_circle.className += " player-circle-away";

            }



            player_circle.style.left = ((this.graph_width / (maxX - minX) * (element["statistics"]['cf60'] - minX))).toFixed(1) + "px";
            player_circle.style.top = ((this.graph_height - (this.graph_height - (this.graph_height / (maxY - minY)) * (element["statistics"]['ca60'] - minY)))).toFixed(1) + "px";
            player_circle.style.width = circle_size + "px";
            player_circle.style.height = circle_size + "px";

            var name = document.createElement("div");
            var name_text = document.createTextNode(element["name"]);
            name.appendChild(name_text);
            name.setAttribute("class", "name");
            name.style.bottom = - circle_size / 2 + 8 + "px";
            name.style.left = circle_size + 10 + "px";
            name.style.zIndex = "" + circle_size;
            player_circle.appendChild(name);


            var label = document.getElementById("tooltip");
            var tooltip_title = document.getElementById("tooltip_title");
            var tooltip_toigp = document.getElementById("tooltip_toigp");
            var tooltip_cfpercent = document.getElementById("tooltip_cfpercent");

            let cas = this.formatTOI(element["statistics"]['timeonice']);


            player_circle.addEventListener('mousemove', function (e) {
              // update tooltip
              var mousePos = stage.getPointerPosition();


              //přetékání tooltipu JEBAT
              label.style.left = 10 + "px";
              label.style.top = 10 + "px";


              label.style.opacity = "1";
              label.style.visibility = "visible";
              tooltip_title.innerHTML = element['name'];
              tooltip_toigp.innerHTML = cas;
              tooltip_cfpercent.innerHTML = element["statistics"]['cf%'].toFixed(1) + "%";
            }, true);

            player_circle.addEventListener('mouseout', function (e) {
              label.style.opacity = "0";
              label.style.visibility = "hidden";
            }, true);



            canvas_inner_for_data.appendChild(player_circle);

          });


          this.loading = false;




        },
          err => {
            //alert("Chyba.");
            this.loading = true;
            this.error = true;
          }
        );



      }, err => {
        //alert("Chyba.");
        this.loading = true;
        this.error = true;
      }
      );



    }, err => {
      //alert("Chyba.");
      this.loading = true;
      this.error = true;
    });



  }


  formatTOI(seconds) {
    var date = new Date(seconds * 1000);
    var hh: any = date.getUTCHours();
    var mm: any = date.getUTCMinutes();
    var ss: any = date.getSeconds();


    let mh = mm + (60 * hh);

    if (mh < 10) { mh = "0" + mh; }
    if (ss < 10) { ss = "0" + ss; }

    return mh + ":" + ss;
  }

  Remap(x, in_min, in_max, out_min, out_max) {
    return ((x - in_min) / (in_max - in_min)) * (out_max - out_min) + out_min;
  }




}
