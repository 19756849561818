import { Component, OnInit } from "@angular/core";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-shotflow",
  templateUrl: "./shotflow.component.html",
  styleUrls: ["./shotflow.component.scss"],
  providers: [DataService],
})
export class ShotflowComponent implements OnInit {
  loading: boolean = true;
  error: boolean = false;

  chartOptions = {};

  chartData = [
    { data: [], label: "Rozdil", borderWidth: 2 },
    { data: [], label: "Team1", borderWidth: 1 },
    { data: [], label: "Team2", borderWidth: 1 },
  ];

  chartLabels = [];

  myColors = [
    {
      backgroundColor: "transparent",
      borderColor: "#FEEA5E",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBackgroundColor: "transparent",
      pointHoverBorderColor: "transparent",
    },
    {
      backgroundColor: "#0a0a0a",
      borderColor: "#0a0a0a",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBackgroundColor: "transparent",
      pointHoverBorderColor: "transparent",
    },
    {
      backgroundColor: "#c8293f",
      borderColor: "#c8293f",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBackgroundColor: "transparent",
      pointHoverBorderColor: "transparent",
    },
  ];

  graph_goals: any = [];
  flow_penalties: any = [];
  flow_data: any = [];
  game_time: number = 0;
  flow_number_of_shots: any = [];
  flow_number_of_shots_max: number = 0;

  flow_raw_data: any = [];

  home_team_short: string = "";
  away_team_short: string = "";

  real_game_time: number = 0;
  hidden_minutes: number = 0;

  hidden_part_width: string = "0px";

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {}

  renderGraph() {
    //flow
    this.flow_penalties = this.flow_raw_data["penalties"];

    /*
    this.flow_penalties = [
      {
        "teamType": "opponent",
        "start": 1,
        "end": 3,
        "startSecond": 0,
        "endSecond": 1200,
        "length": 2,
        "player": {
          "id": 2954,
          "name": "Spencer Humphries"
        }
      }
    ];
    */

    this.flow_data = [];
    this.flow_data = this.flow_raw_data;

    //console.log(JSON.stringify(this.flow_raw_data));

    this.real_game_time = this.flow_raw_data["timeLine"].length;
    this.game_time = 71;

    this.hidden_minutes =
      this.game_time - this.flow_raw_data["timeLine"].length;

    this.chartLabels = [];
    for (var i = 1; i < this.game_time + 1; i++) {
      this.chartLabels.push(Number(i));
    }

    Array.from(Array(71)).forEach((x, i) => {
      if (this.flow_raw_data["timeLine"].hasOwnProperty(i)) {
        this.chartData[1]["data"].push(
          this.flow_raw_data["timeLine"][i]["team"]["shots"] * 1
        );
        this.flow_number_of_shots.push(
          this.flow_raw_data["timeLine"][i]["team"]["shots"]
        );

        this.chartData[2]["data"].push(
          this.flow_raw_data["timeLine"][i]["opponent"]["shots"] * -1
        );
        this.flow_number_of_shots.push(
          this.flow_raw_data["timeLine"][i]["opponent"]["shots"]
        );
      } else {
        this.chartData[1]["data"].push(0);
        this.flow_number_of_shots.push(0);

        this.chartData[2]["data"].push(0);
        this.flow_number_of_shots.push(0);
      }
    });

    this.chartData[1]["data"].forEach((item, index) => {
      let count =
        this.chartData[1]["data"][index] -
        this.chartData[2]["data"][index] * -1;
      this.chartData[0]["data"].push(count);
    });

    this.flow_number_of_shots_max =
      Math.max.apply(Math, this.flow_number_of_shots) + 2;
    this.changeGraphDimensions();

    this.graph_goals = [];
    this.flow_raw_data["goals"].forEach((item, index) => {
      let minute = Math.floor(item["time"] / 60);

      let stadium = item["team_shortcut"];

      //console.log(minute);

      if (stadium == this.home_team_short) {
        //console.log("home");
        this.addGoalToGraph(
          minute,
          this.chartData[1]["data"][minute],
          "home",
          item["shooter"],
          item["assists"],
          item["time"],
          item["team_shortcut"]
        );
      } else if (stadium == this.away_team_short) {
        //console.log("away");
        this.addGoalToGraph(
          minute,
          this.chartData[2]["data"][minute],
          "away",
          item["shooter"],
          item["assists"],
          item["time"],
          item["team_shortcut"]
        );
      }
    });
  }

  addGoalToGraph(
    minute: number,
    shots: number,
    place: string,
    shooter: string,
    assists: any[],
    time: number,
    team: string
  ) {
    let full_size_of_graph = this.flow_number_of_shots_max * 2 + 2;

    let minute_x = (100 / this.game_time) * minute - 1;

    let shot_y = 0;

    if (place == "away") {
      shot_y = 50 - (100 / full_size_of_graph) * (shots * -1);
    } else if (place == "home") {
      shot_y = (100 / full_size_of_graph) * shots + 50;
    }

    this.graph_goals.push({
      x: `calc(${minute_x}% + 9px)`,
      y: `calc(${shot_y}% - 7px)`,
      shooter: shooter,
      assists: assists,
      time: time,
      team: team,
    });
  }

  changeGraphDimensions() {
    this.chartOptions = {
      responsive: false,
      legend: false,
      labels: false,
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
              min: 0,
              callback: function (value) {
                //return value - 1;
                if (
                  value == 1 ||
                  value == 21 ||
                  value == 41 ||
                  value == 61 ||
                  value == 66 ||
                  value == 71
                ) {
                  return value - 1;
                }
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              max: this.flow_number_of_shots_max,
              min: this.flow_number_of_shots_max * -1,
            },
          },
        ],
      },
    };
  }

  getPowerplayStart(time: number) {
    let minute_x = (100 / 4200) * time;
    return minute_x + "%";
  }

  getPowerplayLength(length: number) {
    let minute_x = (100 / this.game_time) * length;
    return minute_x + "%";
  }

  getPowerplayEnd(start_time: number, end_time: number) {
    let pp_length = end_time - start_time;

    let minute_x = (100 / (4200 - 60)) * pp_length;
    return minute_x + "%";
  }

  getPowerplayColour(player: string) {
    //console.log(JSON.stringify(player));
    //return "yellow";
    if (player["teamType"] == "team") {
      return "#f6b6bc";
    } else {
      return "#c4c4c8";
    }
  }

  getAssists(assists_data: any) {
    let names: any = [];

    assists_data["assists"].forEach((item, index) => {
      names.push(item["name"]);
    });
    if (names.length > 0) {
      return "(" + names.join(", ") + ")";
    } else {
      return "";
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        let id = params["id"];

        this.dataService.getShotflow(id).subscribe(
          (loaded_data) => {
            this.flow_raw_data = loaded_data;

            //console.log(JSON.stringify(this.flow_raw_data));

            this.home_team_short =
              this.flow_raw_data["teamInfo"]["team"][0]["shortcut"];
            this.away_team_short =
              this.flow_raw_data["teamInfo"]["opponent"][0]["shortcut"];

            this.hidden_minutes =
              this.game_time - this.flow_raw_data["timeLine"].length;

            this.loading = false;

            this.renderGraph();
          },
          (err) => {
            //alert("Chyba.");
            this.loading = true;
            this.error = true;
          }
        );
      },
      (err) => {
        //alert("Chyba.");
        this.loading = true;
        this.error = true;
      }
    );
  }

  formatTOI(seconds) {
    var date = new Date(seconds * 1000);
    var hh: any = date.getUTCHours();
    var mm: any = date.getUTCMinutes();
    var ss: any = date.getSeconds();

    let mh = mm + 60 * hh;

    if (mh < 10) {
      mh = "0" + mh;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }

    return mh + ":" + ss;
  }

  getHiddenPart() {
    //alert(this.flow_raw_data["timeLine"].length);

    let value = this.hidden_minutes;
    console.log(value);
    let width = "";
    if (value == 1) {
      width = "0px";
    } else if (value == 2) {
      width = "29px";
    } else if (value == 3) {
      width = "43px";
    } else if (value == 4) {
      width = "58px";
    } else if (value == 5) {
      width = "72px";
    } else if (value == 6) {
      width = "86px";
    } else if (value == 7) {
      width = "100px";
    } else if (value == 8) {
      width = "114px";
    } else if (value == 9) {
      width = "128px";
    } else if (value == 10) {
      width = "142px";
    } else if (value == 11) {
      width = "142px";
    }

    this.hidden_part_width = width;
  }
}
